import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { logo, logoTitle, viewMenu } from 'images'
import OrderPickupButton from './OrderPickupButton'
import MenuModal from './MenuModal'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/NmljFgO1p1b0DnWwM4Lw/locations/Ab8324iqgy4BQgWK2Ufi'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <MobileScreen>
        <CFView column center w="100%" h="70px" bg="white">
          <CFImage h="50px" src={logo} alt="Sanjo Sushi Logo" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center w="100%" bg="white" zIndex={98} h="60px">
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="30px"
          >
            <CFView row center>
              <CFImage
                h="45px"
                ml="15px"
                src={logo}
                alt="Sanjo Sushi Logo"
                zIndex={98}
              />
            </CFView>
            <CFView row center>
              <CFView
                h4
                xBold
                hover
                onClick={() => setShowModal(true)}
                mr="25px"
              >
                Menu
              </CFView>
              <CFView mt="3.5px" maxWidth="140px">
                <OrderPickupButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <MenuModal
        showModal={showModal}
        onRequestClose={() => setShowModal(false)}
      />
    </>
  )
}
