import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import { mobileAbout, about } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileAbout} w="100%" alt="about" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center m="0 auto" maxWidth="1400px" bg="#0B0B0B">
          <CFImage src={about} w="100%" alt="about" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
