import React from 'react'
import {
  CFImage,
  CFView,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { regular, lunch, dinner, takeout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column alignCenter w="100%" bg="white" pb="30px" pt="15px">
          <CFView
            bc="black"
            bw="2.5px"
            pv="10px"
            ph="40px"
            minWidth="300px"
            textCenter
            mb="20px"
          >
            <CFLink href={regular} target="_blank">
              <CFView h4 black bold>
                View Dine-In Menu
              </CFView>
            </CFLink>
          </CFView>
          <CFView
            bc="black"
            bw="2.5px"
            pv="10px"
            ph="40px"
            minWidth="300px"
            textCenter
            mb="20px"
          >
            <CFLink href={lunch} target="_blank">
              <CFView h4 black bold>
                Dine-In Lunch Menu
              </CFView>
            </CFLink>
          </CFView>
          <CFView
            bc="black"
            bw="2.5px"
            pv="10px"
            ph="40px"
            minWidth="300px"
            textCenter
            mb="20px"
          >
            <CFLink href={dinner} target="_blank">
              <CFView h4 black bold>
                Dine-In Dinner Menu
              </CFView>
            </CFLink>
          </CFView>
          <CFView
            bc="black"
            bw="2.5px"
            pv="10px"
            ph="40px"
            minWidth="300px"
            textCenter
          >
            <CFLink href={takeout} target="_blank">
              <CFView h4 black bold>
                Take-Out Menu
              </CFView>
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column alignCenter w="100%" bg="white" pb="40px" mt="15px">
          <CFView
            bc="black"
            bw="2.5px"
            pv="10px"
            ph="40px"
            minWidth="300px"
            textCenter
            mb="20px"
            highlightRed
          >
            <CFLink href={regular} target="_blank">
              <CFView h4 black bold>
                Regular Menu
              </CFView>
            </CFLink>
          </CFView>
          <CFView
            bc="black"
            bw="2.5px"
            pv="10px"
            ph="40px"
            minWidth="300px"
            textCenter
            mb="20px"
            highlightRed
          >
            <CFLink href={lunch} target="_blank">
              <CFView h4 black bold>
                Dine-In Lunch Menu
              </CFView>
            </CFLink>
          </CFView>
          <CFView
            bc="black"
            bw="2.5px"
            pv="10px"
            ph="40px"
            minWidth="300px"
            textCenter
            mb="20px"
            highlightRed
          >
            <CFLink href={dinner} target="_blank">
              <CFView h4 black bold>
                Dine-In Dinner Menu
              </CFView>
            </CFLink>
          </CFView>
          <CFView
            bc="black"
            bw="2.5px"
            pv="10px"
            ph="40px"
            minWidth="300px"
            textCenter
            highlightRed
          >
            <CFLink href={takeout} target="_blank">
              <CFView h4 black bold>
                Take-Out Menu
              </CFView>
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
